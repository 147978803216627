:root {
  font-size: 17px;
  font-family: 'Merriweather', serif;
  --primary-dark: darkslategray;
  --secondary-dark: #233d3d;
  --tertiary-dark: #395f5f;
  --primary-light: antiquewhite;
  --secondary-light: mintcream;
  --transition-speed: 750ms;
}

body {
  margin: 0;
  padding: 0;
  color: var(--primary-dark);
  background: var(--tertiary-dark);
}

/* button */
.button {
  background-color: var(--tertiary-dark);
  color: var(--primary-light);
  border: none;
  padding: 0.45rem;
  cursor: pointer;
  font-size: 0.91rem;
  border-radius: 3px;
  margin: 0 0.3rem;
}

.button:hover {
  color: var(--secondary-light);
  transition: var(--transition-speed);
}

/* form */
form {
  width: 50%;
  margin-right: 3rem;
}

input, textarea {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem;
  border: 2px solid var(--primary-dark);
  border-radius: 4px;
  color: var(--secondary-dark);
}

input:focus, textarea:focus {
  background-color: var(--secondary-light);
}

input[type=submit] {
  margin-left: 0.9rem;
}

/* chatroom */
.chatroom-form {
  display: flex;
  margin: 1rem;
}

.chatroom-message-area {
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
  margin-top: -90px;
}

.chatroom-message-area::-webkit-scrollbar {
  width: 0.47rem;
}

.chatroom-message-area::-webkit-scrollbar-track {
  background: var(--secondary-light);
}

.chatroom-message-area::-webkit-scrollbar-thumb {
  background: var(--tertiary-dark);
}

.message {
  display: flex;
  align-items: center;
}

.message p {
  border-radius: 25px;
  padding: 8px 12px;
  max-width: 500px;
}

.message img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin: 2px 5px;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  align-self: flex-end;
  background-color: var(--tertiary-dark);
  color: var(--primary-light);
}

.received p {
  background-color: var(--secondary-light);
  color: var(--tertiary-dark);
}

/* scroller */
body::-webkit-scrollbar {
  width: 0.47rem;
}

body::-webkit-scrollbar-track {
  background: var(--secondary-light);
}

body::-webkit-scrollbar-thumb {
  background: var(--secondary-dark);
}

/* navigation */
.navbar {
  z-index: 1;
  position: fixed;
  background-color: var(--primary-dark);
  transition: width 250ms ease;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.logo {
  font-weight: bold;
  margin-bottom: 0rem;
  text-align: center;
  font-size: 1.4rem;
  letter-spacing: 0.2ch;
  width: 100%;
}

.logo svg {
  transform: rotate(0deg);
  transition: transform var(--transition-speed);
}

.navbar:hover .logo svg {
  transform: rotate(-180deg);
}

.nav-item {
  width: 100%;
}

.nav-item:last-child {
  margin-top: auto;
}

.nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--primary-light);
  text-decoration: none;
  transition: var(--transition-speed);
}

.selected {
  background: var(--secondary-dark);
  color: var(--secondary-light);
}

.nav-link:hover {
  background: var(--secondary-dark);
  color: var(--secondary-light);
}

.nav-link svg {
  width: 2rem;
  min-width: 2rem;
  max-height: 2rem;
  margin: 0 1.5rem;
}

.link-text {
  display: none;
  margin-left: 1rem;
}

.fa-primary, .fa-secondary {
  transition: var(--transition-speed);
}

/* main */
main {
  margin-left: 5rem;
}

section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  padding: 100px 20vw;
}

section ul {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

section li svg {
  color: var(--tertiary-dark);
  height: 100%;
}

section svg {
  width: 2rem;
  height: 2rem;
  display: inline;
  vertical-align: bottom;
  margin: 0 0.4rem;
}

footer {
  z-index: -1;
  margin-left: 5rem;
  color: var(--primary-light);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 10vw;
}

.primary-h1 {
  font-size: 2.5rem;
  margin-top: 1rem;
  color: var(--secondary-dark);
  animation: fadeIn 1.5s linear;
}

@keyframes fadeIn {
  from {opacity:0.1;}
  to {opacity:1;}
}

section img {
  width: 4.5rem;
  height: 4.5rem;
  display: inline;
  vertical-align: bottom;
  margin-left: 1.2rem;
}

.antique {
  background: var(--primary-light);
}

.cream {
  background: var(--secondary-light);
}

.wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 49px;
}

.wave .shape-fill {
  fill: var(--primary-light);
}

.antique .wave .shape-fill {
  fill: var(--secondary-light);
}

/* small screens */
@media only screen and (max-width: 700px) {
  .navbar {
      bottom: 0;
      width: 100vw;
      height: 5rem;
  }

  .navbar-nav {
      flex-direction: row;
  }

  .nav-link {
      justify-content: center;
  }

  .nav-link svg {
      width: 1.7rem;
      min-width: 1.7rem;
      max-height: 1.6rem;
      margin: 0 1.2rem;
  }

  main {
      margin: 0;
  }

  footer {
      margin: 0;
  }

  form {
    width: 100%;
  }

  .chatroom-message-area {
    width: 95vw;
  }
}

/* large screens */
@media only screen and (min-width: 700px) {
  .navbar {
      top: 0;
      width: 5rem;
      height: 100vh;
  }

  .navbar:hover {
      width: 15.7rem;
  }

  .navbar:hover .link-text {
      display: inline;
  }

  .navbar:hover .logo .nav-link {
    background: var(--secondary-dark);
    color: var(--secondary-light);
  }
}